import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import RightArrow from "../../images/svg/right-arrow.svg";
import imageUrl from "../../helpers/flotiqImage";

const PortfolioProjectFeaturedTile = ({project, projectsInCategory}) => {
    let portfolioFeaturedTileStyle = {
        backgroundImage: `url(${imageUrl(project.portfolioImage)})`
    }

    return (
        <AniLink duration={.5} fade to={`/portfolio/${project.slug}`} className="portfolio-project-featured-tile"  style={portfolioFeaturedTileStyle} state={{projectsInCategory}}>
            <div className="portfolio-project-featured-tile__content-wrapper">
                <div className="portfolio-project-featured-tile__client-logo">
                    <img alt={project.client[0].name} src={imageUrl(project.client[0].logoColored, {width: 75, height: 75})}/>
                </div>
                <p className="portfolio-project-featured-tile__project-name">{project.name}</p>
                <h3 className="portfolio-project-featured-tile__client-name">
                    <span>for </span>{project.client[0].name}
                </h3>
                <div className="portfolio-project-featured-tile__read-more">
                    <p className="read-more-text">read more</p>
                    <RightArrow/>
                </div>
            </div>
        </AniLink>
    )
}

export default PortfolioProjectFeaturedTile