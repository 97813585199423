import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioIntroSection from "../sections/porfolio/portfolio-intro-section";
import PortfolioContentSection from "../sections/porfolio/portfolio-content-section";
import {graphql, useStaticQuery} from "gatsby";
import CTASection from "../sections/cta-section";

const PortfolioPage = () => {
    const data = useStaticQuery(graphql`
        query {
            portfolioIntroSectionBG: file(relativePath: { eq: "backgrounds/intro-bg.jpg" }) {
                publicURL
            }
        }
    `);

    let portfolioIntroSectionStyle = {
        background: `radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 90%,rgba(255,255,255,.7) 100%), url(${data.portfolioIntroSectionBG.publicURL})`
    }

    return (
        <Layout>
            <SEO title="Portfolio"/>
            <div className="subpage-main-wrapper" style={portfolioIntroSectionStyle}>
                <PortfolioIntroSection/>
                <PortfolioContentSection/>
            </div>
            <CTASection/>
        </Layout>
    )
}

export default PortfolioPage
