import React, {useEffect, useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

function isCategorySelected (slug){
    if(typeof window === "undefined") return;
    return (new RegExp(`${slug}$`)).test(window.location.hash)
}
const PortfolioContentNavigation = ({categories = [], relativeTo = null, screenOffset = 80}) => {
    const ref = React.createRef();

    const [positionModifier, setPositionModifier] = useState(null);

    useEffect(() => {
        if(!relativeTo || !relativeTo.current || !ref || !ref.current)
            return;

        const onScroll = () => {
            const otherBox = relativeTo.current.getBoundingClientRect();
            const myBox = ref.current.getBoundingClientRect();
            if(otherBox.y < screenOffset) {
                if(otherBox.y + otherBox.height - myBox.height - screenOffset > 0)
                    setPositionModifier('fixed');
                else 
                    setPositionModifier('stick-bottom');
            } else {
                setPositionModifier(null);
            }
        }

        window.addEventListener('scroll', onScroll)

        return () => {window.removeEventListener('scroll', onScroll)}
    }, [relativeTo, ref, screenOffset])

    return <div ref={ref} className={`portfolio-content-navigation ${positionModifier ? `portfolio-content-navigation--${positionModifier}` : ''}`}>
        <ul>
            {categories.map(category => 
                <li key={category.slug}>
                    <AnchorLink 
                        className={isCategorySelected(category.slug) ? 'active' : ''} 
                        to={'/portfolio/' + (category.slug.length ? "#" + category.slug : '')}>{category.name}</AnchorLink>
                </li>
                )}
        </ul>
    </div>
}

export default PortfolioContentNavigation