import React from "react"

import imageUrl from "../../helpers/flotiqImage";
const PortfolioProjectTile = ({project}) => (
    <div className="portfolio-project-tile">
        <div className="portfolio-project-tile__client-logo">
            <img alt={project.client[0].name} src={imageUrl(project.client[0].logoColored, {width: 75, height: 75})}/>
        </div>
        <h4 className="portfolio-project-tile__project-name">
            {project.name}
        </h4>
        <p className="portfolio-project-tile__project-info" dangerouslySetInnerHTML={{__html: project.shortDescription}}></p>
    </div>
)

export default PortfolioProjectTile