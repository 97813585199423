import React from "react"

const PortfolioIntroSection = () => (
    <section className="cw-container-fluid">
        <div className="cw-container subpage-intro-section subpage-intro-section--portfolio">
            <h1>Our Projects</h1>
            <p>We are always looking for new challenges and our customers’ opinion confirms this.</p>
        </div>
    </section>
)

export default PortfolioIntroSection