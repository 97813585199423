import {graphql, useStaticQuery} from "gatsby";

import React, {Fragment} from "react"
import PortfolioContentNavigation
    from "../../components/portfolio/portfolio-content-navigation";
import PortfolioProjectFeaturedTile
    from "../../components/portfolio/protfolio-project-tile-featured";
import PortfolioProjectTile
    from "../../components/portfolio/protfolio-project-tile";


const PortfolioContentSection = () => {
    let data = useStaticQuery(graphql`
        query {
            categories: allCodewaveProjectCategory(sort: {fields: order}, filter: {isPublic: {eq: true}}) {
                nodes {
                    id
                    name
                    slug
                }
            },
            projects: allCodewaveProject(sort: {fields: isCaseStudy, order: DESC}, filter: {isPublic: {eq: true}}) {
                nodes {
                    categories {
                        slug
                    }
                    name
                    portfolioImage {
                        id
                        extension
                    }
                    client {
                        name
                        logo {
                            id
                            extension
                        },
                        logoColored {
                            id
                            extension
                        }
                    }
                    slug
                    shortDescription
                    isCaseStudy
                    paginationThumbnail {
                        extension
                        id
                    }
                }
            }
        }`);
    const categories = data.categories.nodes;

    const projects = data.projects.nodes;
    const projectByCategory = projects.reduce((acc, project) => {
        project.categories.forEach(projectCategory => {
            if (!acc[projectCategory.slug]) {
                acc[projectCategory.slug] = []
            }
            acc[projectCategory.slug].push(project);
        })
        return acc;
    }, {});


    const contentColumnRef = React.createRef();

    return <section className="cw-container-fluid">
        <div className="cw-container content-section portfolio-content-section">
            <div className="portfolio-content-section__navigation-column">
                <PortfolioContentNavigation categories={categories} relativeTo={contentColumnRef}/>
            </div>
            <div className="portfolio-content-section__main-column" ref={contentColumnRef}>
                {categories.map(c =>
                    <Fragment key={c.slug}>
                        <h2 id={c.slug}
                            className="section-header">{c.name}</h2>
                        {
                            projectByCategory[c.slug] && projectByCategory[c.slug].map(project =>
                                project.isCaseStudy
                                    ? <PortfolioProjectFeaturedTile
                                        key={project.slug} project={project}
                                        projectsInCategory={projectByCategory[c.slug].filter(p => p.isCaseStudy)}/>
                                    : <PortfolioProjectTile key={project.slug}
                                                            project={project}/>
                            )
                        }

                    </Fragment>
                )}
            </div>
        </div>
    </section>
}


export default PortfolioContentSection